import React, { useEffect, useState, useRef } from 'react';
import './App.css';

function App() {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState('dark');
  const [source, setSource] = useState('whalecrypto');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [postCount, setPostCount] = useState(9);
  const [dateFilter, setDateFilter] = useState('all');
  const [modalPost, setModalPost] = useState(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [cryptoPrices, setCryptoPrices] = useState([]);
  const tickerRef = useRef(null);

  const sources = {
    whalecrypto: {
      name: 'Crypto Whale Medium',
      rss: 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@whalecrypto',
    },
    coindesk: {
      name: 'Coindesk',
      rss: 'https://api.rss2json.com/v1/api.json?rss_url=https://www.coindesk.com/arc/outboundfeeds/rss/',
    },
    cointelegraph: {
      name: 'Cointelegraph',
      rss: 'https://api.rss2json.com/v1/api.json?rss_url=https://cointelegraph.com/rss',
    },
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    const fetchPosts = async () => {
      setLoading(true);
      setError(null);
      const RSS_URL = sources[source].rss;

      try {
        const response = await fetch(RSS_URL);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data && data.items) {
          const feedItems = data.items.map(item => ({
            title: item.title,
            link: item.link,
            pubDate: new Date(item.pubDate || item.pubdate),
            content: item.content || item.description,
            guid: item.guid || item.link,
            image: extractImage(item.content) || item.thumbnail || item.enclosure?.link || '',
          }));

          let filteredByDate = filterPostsByDate(feedItems);
          setPosts(filteredByDate);
          setFilteredPosts(filteredByDate.slice(0, postCount));
        } else {
          setError('No posts found');
        }
      } catch (err) {
        console.error('Error fetching the RSS feed:', err);
        setError('Error fetching the RSS feed');
      } finally {
        setLoading(false);
      }
    };

    const fetchCryptoPrices = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false');
        if (!response.ok) {
          throw new Error('Failed to fetch crypto prices');
        }
        const data = await response.json();
        setCryptoPrices(data);
      } catch (error) {
        console.error('Error fetching crypto prices:', error);
      }
    };

    fetchPosts();
    fetchCryptoPrices();

    const intervalId = setInterval(fetchCryptoPrices, 60000);
    return () => clearInterval(intervalId);
  }, [source, postCount, dateFilter, theme]);

  useEffect(() => {
    if (tickerRef.current && cryptoPrices.length > 0) {
      const ticker = tickerRef.current;
      ticker.innerHTML += ticker.innerHTML;

      const animateTicker = () => {
        if (ticker.scrollLeft >= ticker.scrollWidth / 2) {
          ticker.scrollLeft = 0;
        } else {
          ticker.scrollLeft += 1;
        }
        requestAnimationFrame(animateTicker);
      };

      requestAnimationFrame(animateTicker);
    }
  }, [cryptoPrices]);

  const extractImage = (content) => {
    const imgRegex = /<img.*?src="(.*?)".*?>/;
    const match = content.match(imgRegex);
    return match ? match[1] : null;
  };

  const getExcerpt = (content, maxLength = 150) => {
    const strippedContent = content.replace(/<[^>]+>/g, '');
    return strippedContent.length > maxLength
      ? strippedContent.substring(0, maxLength) + '...'
      : strippedContent;
  };

  const filterPostsByDate = (posts) => {
    const now = new Date();
    if (dateFilter === 'today') {
      return posts.filter(post => post.pubDate.toDateString() === now.toDateString());
    } else if (dateFilter === 'week') {
      const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
      return posts.filter(post => post.pubDate >= oneWeekAgo);
    } else if (dateFilter === 'month') {
      const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      return posts.filter(post => post.pubDate >= oneMonthAgo);
    }
    return posts;
  };

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  const handleSourceChange = (e) => {
    setSource(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePostCountChange = (e) => {
    setPostCount(Number(e.target.value));
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handlePostClick = (post) => {
    setModalPost(post);
  };

  const closeModal = () => {
    setModalPost(null);
  };

  const optimizeImages = (content) => {
    return content.replace(/<img /g, '<img style="max-width: 100%; height: auto;" ');
  };

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  useEffect(() => {
    if (searchTerm) {
      const filtered = posts.filter(post =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPosts(filtered.slice(0, postCount));
    } else {
      setFilteredPosts(posts.slice(0, postCount));
    }
  }, [searchTerm, posts, postCount]);

  return (
    <div className="whale-crypto">
      <header className="main-header">
        <div className="header-left">
          <img 
            src="https://pbs.twimg.com/profile_images/1802796436695691267/HMoWep6W_400x400.jpg" 
            alt="Whale Crypto Logo" 
            className="logo"
          />
          <div className="header-text">
            <h1>WHALE CRYPTO</h1>
            <p>QUALITY PUBLICATION • NY • CRYPTO • LA</p>
          </div>
        </div>
        <div className="header-right">
          <p className="date">{new Date().toDateString()}</p>
        </div>
      </header>
      
      <div className="crypto-ticker-container">
        <div className="crypto-ticker" ref={tickerRef}>
          {cryptoPrices.map((crypto) => (
            <span key={crypto.id} className="crypto-item">
              <span className="crypto-symbol">{crypto.symbol.toUpperCase()}</span>
              <span className="crypto-price">${crypto.current_price.toFixed(2)}</span>
              <span className={`crypto-change ${crypto.price_change_percentage_24h > 0 ? 'positive' : 'negative'}`}>
                {crypto.price_change_percentage_24h > 0 ? '+' : ''}
                {crypto.price_change_percentage_24h.toFixed(2)}%
              </span>
            </span>
          ))}
        </div>
      </div>
      
      <div className={`sidebar ${sidebarExpanded ? 'expanded' : ''}`}>
        <div className="hamburger" onClick={toggleSidebar}>☰</div>
        {sidebarExpanded && (
          <div className="controls">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search articles..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="filters">
              <label>
                Show:
                <select value={postCount} onChange={handlePostCountChange}>
                  <option value="5">5 articles</option>
                  <option value="9">9 articles</option>
                  <option value="15">15 articles</option>
                </select>
              </label>
              <label>
                Date Range:
                <select value={dateFilter} onChange={handleDateFilterChange}>
                  <option value="all">All Time</option>
                  <option value="today">Today</option>
                  <option value="week">This Week</option>
                  <option value="month">This Month</option>
                </select>
              </label>
              <label>
                Source:
                <select value={source} onChange={handleSourceChange}>
                  {Object.keys(sources).map((key) => (
                    <option value={key} key={key}>
                      {sources[key].name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="theme-toggle" onClick={toggleTheme}>
              {theme === 'dark' ? '🌞' : '🌙'}
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <div className="loader">Loading...</div>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <main>
          <section className="featured-post">
            {filteredPosts[0] && (
              <article onClick={() => handlePostClick(filteredPosts[0])}>
                <img src={filteredPosts[0].image} alt={filteredPosts[0].title} />
                <div className="content">
                  <h2>{filteredPosts[0].title}</h2>
                  <p>{getExcerpt(filteredPosts[0].content, 200)}</p>
                  <span className="read-more">READ MORE →</span>
                </div>
              </article>
            )}
          </section>
          
          <section className="post-grid">
            {filteredPosts.slice(1).map((post) => (
              <article key={post.guid} onClick={() => handlePostClick(post)}>
                <img src={post.image} alt={post.title} />
                <h3>{post.title}</h3>
                <p>{post.pubDate.toLocaleDateString()}</p>
              </article>
            ))}
          </section>
        </main>
      )}

      {modalPost && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>{modalPost.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: optimizeImages(modalPost.content) }} />
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      {/* About Section */}
      <section className="about-section">
        <h2>About Crypto Whale Medium</h2>
        <p>
          Welcome to Crypto Whale Medium, your trusted source for comprehensive insights and analysis on the dynamic world of cryptocurrencies. 
          Our editorial team curates in-depth articles and expert commentary, covering essential topics such as blockchain technology, market trends, 
          trading strategies, and regulatory updates.
        </p>
        <p>
          Stay informed and empowered with our timely content, designed to cater to both seasoned investors and newcomers alike. Explore the latest 
          developments in digital assets and cryptocurrency markets on Medium. Follow us for authoritative perspectives and join the discussion using hashtags like 
          <strong> #CryptoWhaleMedium #CryptocurrencyNews #BlockchainInsights #CryptoAnalysis #DigitalAssets #CryptoEducation</strong>.
        </p>
      </section>

      {/* Contact Section */}
      <section className="contact-section">
        <h2>Contact Us</h2>
        <p>
          For any inquiries or collaborations, feel free to reach out via the following platforms:
        </p>
        <ul>
          <li>
            <a href="https://medium.com/@whalecrypto" target="_blank" rel="noopener noreferrer">
              Medium: https://medium.com/@whalecrypto
            </a>
          </li>
          <li>
            <a href="https://x.com/CryptoWhaleM" target="_blank" rel="noopener noreferrer">
              X (formerly Twitter): https://x.com/CryptoWhaleM
            </a>
          </li>
          <li>Email: <a href="mailto:medium@whalecrypto.org">medium@whalecrypto.org</a> or <a href="mailto:info@whalecrypto.org">info@whalecrypto.org</a></li>
        </ul>
      </section>

      <footer className="main-footer">
        <div className="footer-content">
          <p>&copy; 2024 Crypto Whale Medium. All rights reserved.</p>
          <div className="social-links">
            <a href="https://x.com/CryptoWhaleM" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://medium.com/@whalecrypto" target="_blank" rel="noopener noreferrer">Medium</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
